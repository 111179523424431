import HttpClient from './HttpClient'

class TenantsApiClient extends HttpClient {

    constructor(baseURL, token) {
      super({
        baseURL,
        token
      });
    }
  
    get userProfile() {
      return {
        get: () => this.get('user-tenants')
      };
    }
  
  }
  
  export default TenantsApiClient