import { createSlice } from '@reduxjs/toolkit';
import ApiClientConnector from 'src/api/ApiClientConnector';

const initialState = {
    hotels: [],
};
export const ManageHotelSlice = createSlice({
    name: 'hotels',
    initialState,
    reducers: {
        getHotelResultList: (state, action) => {
            state.hotels = action.payload;
        },
    },
});

export const {
    getHotelResultList
} = ManageHotelSlice.actions;

export const fetchHotels = (instance, accounts, criteria) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance, accounts);
        const result = await client.hotels.post(criteria);
        const hotelsArray = result.Results || [];
        dispatch(getHotelResultList(hotelsArray));
    } catch (error) {
        console.error("Error fetching Hotels:", error);
    }
};




export default ManageHotelSlice.reducer;