import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    agentList: [],
    agentUserList: []
};

export const AgentManagerSlice = createSlice({
    name: 'agents',
    initialState,
    reducers: {
        getAgentList: (state, action) => {
            state.agentList = action.payload;
        },
        addAgent: (state, action) => {
            state.agentUserList = [...state.agentUserList, { ...action.payload }];
        },
        setAgentUserList: (state, action) => {
            state.agentUserList = action.payload;
        },
    }
})

export const { getAgentList, addAgent, setAgentUserList } = AgentManagerSlice.actions;

export const fetchAgents = (instance, accounts, criteria) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance, accounts);
        const result = await client.agents.get(criteria);
        dispatch(getAgentList(result));
        return result;
    } catch (error) {
        console.log(error);

    }
}

export const addAgentUser = (instance, accounts, data, agentId) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance, accounts);
        const response = await client.agents.post(agentId, data);
        dispatch(addAgent(response));
        return response;
    }
    catch (error) {
        throw error;
    }
}

export default AgentManagerSlice.reducer;