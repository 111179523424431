import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store/Store';
import Spinner from './views/spinner/Spinner';

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <Suspense fallback={<Spinner />}>
          <App />
      </Suspense>
    </MsalProvider>
  </Provider>
)
