import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sortBy: 'priceAsc',
  selectedFacilities: [],
};

export const FilterHotelSlice = createSlice({
  name: 'filter-hotels',
  initialState,
  reducers: {
      sortByLowestPriceHotels(state, action) {
          state.sortBy = action.payload;
      },
      setSelectedFacilities(state, action) {
          state.selectedFacilities = action.payload;
      },
  },
});

export const {
  sortByLowestPriceHotels,
  setSelectedFacilities,
} = FilterHotelSlice.actions;

export default FilterHotelSlice.reducer;
