
import appConfig, { loginRequest } from "../authConfig";
import OkloReservationsApiClient from "./OkloReservationsApiClient";

const ApiClientConnector = async (instance, accounts) => {
	const tenantId = localStorage.getItem('current-tenant') != null ? localStorage.getItem('current-tenant') : "";
	const token = await instance.acquireTokenSilent({
		account: accounts[0],
		scopes: loginRequest.scopes,
		});
		const client = new OkloReservationsApiClient(
			appConfig.apiBaseUrl,
			token.accessToken,
			tenantId
		);
		return client;
}

export default ApiClientConnector;